import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
// import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    // padding: theme.spacing(0, 0.1),
    backgroundColor: "ffffff"
  },
  paper: {
    maxWidth: 700,
    margin: `${theme.spacing(0.7)}px auto`,
    padding: theme.spacing(3),
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    display: "center",
  },
}));

function UserCard(props) {
  const user = props.user;
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.paper}>
        {/* <h1>{`${user.firstName} ${user.lastName}'s Notes`}</h1>
        <p>{`@${user.username}`}</p>
        <h4>{`${user.userbio}`}</h4> */}
        <CardHeader
          title={`${user.firstName} ${user.lastName}'s Notes`}
          // subheader={`@${user.username} | ${user.userbio}`}
          subheader={`${user.userbio}`}
        />
        
      </Card>

    </div>

  );
}

UserCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default UserCard;
