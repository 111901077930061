import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import logo from "../../illustrations/readwme_logo.png"
import { Box, Typography } from "@material-ui/core";
import vid1 from '../../imgs/vid1.gif';
import vid2 from '../../imgs/vid2.gif';
import vid3 from '../../imgs/vid3.gif';
import prof1 from '../../imgs/prof2.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    backgroundColor: "#ffffff",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "center"
  },
  paper: {
    maxWidth: 1000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: "#ffffff"
  },
  eachNote: {
    // maxWidth: 800,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: "none",
    borderRadius: "1px",
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    // color: "red" -> this is font color
  },
  articleTitle: {
     textDecoration: 'none',
    //  fontSize: '5.25em',
     color: "#1c1c1c",
     fontWeight: 'bold'
  },
  footer: {
    textDecoration: 'none',
    fontSize: '13px',
    color: "#1c1c1c",
    maxWidth: 750,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: "none",
    borderRadius: "1px",
    boxShadow: 'none',
    backgroundColor: "#ffffff",
  },
  articleTitle2: {
     paddingBottom: '0.1rem',
     textDecoration: 'none',
     fontSize: '35px',
     color: "#1c1c1c"
  },
  subheader: {
     textDecoration: 'none',
     color: "#1c1c1c"
  },
  gap: {
  },
  gap2: {
    fontSize: '100px'
  },
  dlbutton: {
    backgroundColor: "#1c1c1c",
    color: "#ffffff",
    fontSize: '20px',
    textTransform: 'none',
  },
  image: {
    resizeMode: 'contain',
    flex: 1,
    aspectRatio: 1
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  logoImg: {
    // paddingLeft: '7.25em'
  }
}));

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

function getImageDimensions(width) {
  let imageWidth;
  let imageHeight;
  let headerPadding = false;
  let headerFontSize = 4.25;
  let subheaderFont = false;
  
  if (width < 295) {
    imageWidth = 132;
    imageHeight = 88;
    headerFontSize = 1.6;
    subheaderFont = 1.25;
    headerPadding = imageWidth + width / 3
  } 
  else if (width < 340) {
    imageWidth = 153;
    imageHeight = 102;
    headerFontSize = 1.6;
    subheaderFont = 1.25;
    headerPadding = imageWidth + width / 3
  } else if (width < 370) {
    imageWidth = 192;
    imageHeight = 128;
    headerFontSize = 1.7;
    subheaderFont = 1.3;
    headerPadding = imageWidth + width / 7
  } else if (width < 400) {
    imageWidth = 210;
    imageHeight = 140;
    headerFontSize = 2.2;
    subheaderFont = 1.5;
    headerPadding = imageWidth + width / 7
  } else if (width < 460) {
    imageWidth = 240;
    imageHeight = 160;
    subheaderFont = 1.5;
    headerFontSize = 2.3;
    headerPadding = imageWidth + width / 7
  } else if (width < 500) {
    imageWidth = 270;
    subheaderFont = 1.5;
    imageHeight = 180;
    headerFontSize = 2.7;
    headerPadding = imageWidth + width / 7
  } else if (width < 580) {
    imageWidth = 330;
    imageHeight = 220;
    headerFontSize = 3.1;
  } else if (width < 650) {
    imageWidth = 360;
    imageHeight = 240;
    headerFontSize = 3.5;
    headerPadding = imageWidth + width / 10
  } else if (width < 790) {
    imageWidth = 406;
    imageHeight = 264;    
    headerFontSize = 3.75;
    headerPadding = imageWidth + width / 7
  } 
  else if (width < 1040) {
    imageWidth = 540;
    imageHeight = 360;
    headerFontSize = 4;
  } else if (width > 1040) {
    imageWidth = 525;
    imageHeight = 350;
    headerPadding = imageWidth + width / 5.5;
  }
  if (!headerPadding) {
    headerPadding = imageWidth;
  }
  if (!subheaderFont) {
    subheaderFont = 2;
  }

  return { imageWidth, imageHeight, headerFontSize, headerPadding, subheaderFont }
}

export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export default function HomePageGrid(props) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const { imageWidth, imageHeight, headerFontSize, headerPadding, subheaderFont } = getImageDimensions(width);
  if (true) {
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container direction="column" wrap="nowrap" spacing={2}>

              <Grid className={classes.eachNote} item xs>
              
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <h3 >
                  <a>
                    <img src={logo} alt="readwme" height={imageHeight / 1.18} width={imageWidth / 1.2} />
                  </a>
                </h3>

                <p style={{fontSize: `${subheaderFont * 0.9}em`}} className={classes.subheader}> Read in your browser like it's an e-reader. Highlight, and view the most frequently highlighted sentences in any article. </p>
                
              <Button className={classes.dlbutton} variant="contained" target="_blank" href="https://chrome.google.com/webstore/detail/readwme/ogbifmejpgplpibpogjlpacojclkejeb?hl=en">
                    Install Chrome Extension
              </Button>
              </Grid>

                <h3 className={classes.gap2} >
                </h3>

                <Typography className={classes.articleTitle} style={{fontSize: `${headerFontSize * 2 / 3}em`}}> How it works </Typography>

                <p style={{fontSize: `${subheaderFont * 0.8}em`}} className={classes.subheader}> 1. Highlight sentences from any article </p>

                <h3 style={{paddingTop: '1.5em', paddingLeft: `${headerPadding / 160}em`   }} >
                  <a>
                    <img src={vid1} alt="readwme" height={imageHeight * 1.3} width={imageWidth * 1.3} />
                  </a>
                </h3>


                <p style={{fontSize: `${subheaderFont * 0.8}em`}} className={classes.subheader}> 2. Come back to the page any time to view and navigate the page via your highlights </p>

                <h3 style={{paddingTop: '1.5em', paddingLeft: `${headerPadding / 160}em`   }} >
                  <a>
                    <img src={vid2} alt="readwme" height={imageHeight * 1.3} width={imageWidth * 1.3} />
                  </a>
                </h3>

                <p style={{fontSize: `${subheaderFont * 0.8}em`}} className={classes.subheader}> 3. If others have highlighted on this page, view the most popular highlights. </p>

                <h3 style={{paddingTop: '1.5em', paddingLeft: `${headerPadding / 160}em`   }} >
                  <a>
                    <img src={vid3} alt="readwme" height={imageHeight * 1.3} width={imageWidth * 1.3} />
                  </a>
                </h3>

                <p style={{fontSize: `${subheaderFont * 0.8}em`}} className={classes.subheader}> 4. [Webapp beta] View and share what you've read, export your highlights. </p>

                <h3 style={{paddingTop: '1.5em', paddingLeft: `${headerPadding / 160}em`   }} >
                  <a>
                    <img src={prof1} alt="readwme" height={imageHeight * 1.3} width={imageWidth * 1.3} />
                  </a>
                </h3>

                <p style={{fontSize: `${subheaderFont * 0.8}em`}} className={classes.subheader}> 5. Share what you've read with others and send a link to your highlights. Others can see what you've highlighted on the page if they install the extension as well! </p>

                <h3 style={{paddingTop: '1.5em', paddingLeft: `${headerPadding / 160}em`   }} >
                  <a>
                    <img src={prof1} alt="readwme" height={imageHeight * 1.3} width={imageWidth * 1.3} />
                  </a>
                </h3>
              
            </Grid>

          </Grid>
        </Paper>
        <Paper>
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            <h3 className={classes.footer} >
              <a href="/about"> About </a>
            </h3>
          </Grid>
        </Paper>
      </div>
    );
  } else return <></>
}
