import React, { useState, useEffect } from "react";
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import { useParams } from "react-router-dom";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { firestore, auth } from "../../firebase";
import TwitterIcon from '@material-ui/icons/Twitter';
import { Card, CardHeader } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    backgroundColor: "#ffffff"
  },
  paper: {
    maxWidth: 900,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: "#ffffff"
  },
  userHeading: {
    maxWidth: 700,
    margin: `${theme.spacing(0.7)}px auto`,
    padding: theme.spacing(3),
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    display: "center",
  },
  hashtagsLinks : {
    margin: `${theme.spacing(0.7)}px auto`,
    outline: 'none',
    boxShadow: 'none',
    display: "center",
    fontSize: '1em',
    color: '#4a4a4a',
    cursor: 'pointer',
  },
  hashtagsLinksFirst : {
    margin: `${theme.spacing(0.7)}px auto`,
    outline: 'none',
    boxShadow: 'none',
    display: "center",
    fontSize: '1.2em',
    color: '#4a4a4a',
    cursor: 'pointer',
    textDecorationLine: 'underline',
    paddingRight: "0.2em"
  },
  welcomeNote: {
    maxWidth: 700,
    minHeight: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: "none",
    borderTop: "1.5px solid #383838",
    borderRadius: "1px",
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    // color: "red" -> this is font color
    fontSize: '1.3em'
  },
  eachNote: {
    maxWidth: 700,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: "none",
    borderTop: "1.5px solid #383838",
    borderRadius: "1px",
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    // color: "red" -> this is font color
    fontSize: '1.3em'
  },
  menuExpand: {
    color: "#ffffff",
    outline: "none",
  },
  articleTitleog: {
    textDecoration: 'none',
    color: "#1c1c1c",
  },
  articleTitle: {
     textDecoration: 'none',
     color: "#1c1c1c",
     "&:hover, &.Mui-focusVisible": { textDecoration: 'underline' },
  },
  domainname: {
    fontSize: '0.8em',
    color: '#4a4a4a'
  },
  profileName: {
    textDecoration: 'none',
    cursor: 'pointer',
  },
  userTitleStyle: {
    fontSize: '2.5em',
    cursor: 'pointer',
  },
  userNameStyle: {
    fontSize: '1em',
    fontWeight: 400,
    color: '#4a4a4a'
  },
  userBioStyle: {
    fontSize: '1.1em',
    fontWeight: 500,
    color: '#4a4a4a'
  },
  tstampstyleog: {
    fontSize: '0.8em',
    color: '#919191',
    paddingLeft: '5px',
    cursor: 'pointer',
 },
  tstampstyle: {
    fontSize: '0.8em',
    color: '#919191',
    paddingLeft: '5px',
    cursor: 'pointer',
    "&:hover, &.Mui-focusVisible": { textDecoration: 'underline' }
 },
 hlitlistog: {
  fontSize: '0.9em',
  color: '#1c1c1c',
  paddingBottom: '0.7em',
  textDecoration: 'none',
},
 hlitlist: {
  fontSize: '0.9em',
  color: '#1c1c1c',
  paddingBottom: '0.7em',
  textDecoration: 'none',
  "&:hover, &.Mui-focusVisible": { textDecoration: 'underline' },
},
sharebutton: {
    color: '#919191',
    fontSize: '1.15em',
    "&:hover, &.Mui-focusVisible": { color: "#000000" },
    cursor: 'pointer'
},
tagDialog: {
  minWidth: '30em',
  paddingLeft: '1em'
},
container: {
  display: 'flex',
  flexWrap: 'wrap',
},
formControl: {
  margin: theme.spacing(1),
  minWidth: 120,
},
button: {
  margin: theme.spacing(0.5),
  backgroundColor: '#1c1c1c',
  color: 'white',
  textTransform: "lowercase",
  fontSize: '1.3em'
},
tagStyle: {
  margin: theme.spacing(0.5),
  backgroundColor: 'white',
  color: '#7d7d7d',
  textTransform: "lowercase",
  fontSize: '0.8em',
  marginBottom: '0.3em'
}
}));

const exportMarkdown = (note) => {
  let ftitle = note.title
  let ntitle = "# " + note.title + "\n"
  let nurl = "## " + note.paper_url + "\n"
  let snippets = note.hlits.join("\n")

  const element = document.createElement("a");
  const file = new Blob([ntitle, nurl, snippets],    
              {type: 'text/plain;charset=utf-8'});
  element.href = URL.createObjectURL(file);
  element.download = `${ftitle}.md`;
  document.body.appendChild(element);
  element.click();
}

let getHostname = (hn) => {
  hn = hn.split('/')[0]
  if (hn.substring(0, 3) == "www") {
    return hn.substring(4, hn.length);
  }
  else {
    return hn;
  }
}

let getTimeTheyRead = (tstamp) => {
  let components = tstamp.split(" ")
  let month = components[1]
  let day = components[2]
  // let year = components[3].substring(2, components[3].length)

  return month + ". " + day
  // return "Read on " + month + ". " + day
  // return "| " + month + ". " + day
  // return month + ". " + day + " '" + year
}

let getSingleNoteURL2 = (url1, userId, oid) => {
  if (oid !== undefined) {
    return userId + "/note/" + oid.toString();
  }
  let surl = url1.split("www.")
  if (surl[0] == '') {
    return userId + "/note/" + encodeURIComponent(url1)
  } else {
    return userId + "/note/" + encodeURIComponent(surl[0])
  }

}

let getSingleNoteURL = (url1, userId, oid) => {
  if (oid !== undefined) {
    return userId + "/note/" + oid.toString();
  }
  let surl = url1.split("www.")
  if (surl[0] == '') {
    return "readwme.app/n/" + userId + "/note/" + encodeURIComponent(url1)
  } else {
    return "readwme.app/n/" + userId + "/note/" + encodeURIComponent(surl[0])
  }

}

const filter = createFilterOptions();

export default function AutoGridNoWrap({notes, myPage, user}) {
  const classes = useStyles();
  const { userId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [docTags, setDocTags] = React.useState([]);
  const [userTags, setUserTags] = React.useState([]);
  const [userHlitId, setuserHlitId] = React.useState([]);
  const [userTagsList, setUserTagsList] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [tagsLoading, setDocTagsLoading] = useState(false)
  const [curdocid, setcurdocid] = useState(null)
  const [curNote, setCurNote] = useState(null)
  const [temp, setTemp] = useState(0);
  const [filterTag, setFilterTag] = useState("all");
  
  // gets the tags collection that's assigned to each user
  useEffect(async () => {
      let userRef = firestore.doc("users/" + user.emailaddy);
      const doc = await userRef.get();
      
      let userRef2 = firestore.doc("extensionHighlights/" + user.emailaddy);
      let doc2 = await userRef2.get();

      let userHlitId = doc2.data();
      if (userHlitId != undefined) {
        userHlitId = userHlitId.userInfo["uid"]
      }
      setuserHlitId(userHlitId);

      const url = new URL(window.location);
      let searchTag = url.searchParams.get('tag');
      if (searchTag !== null) {
        setFilterTag(searchTag)
      }
      
      let utags = []
      if (doc.data()["tags"] != undefined) {
        utags = doc.data()["tags"]; 
      }
      setUserTagsList(utags)
      let userTags = []

      for (let i = 0; i < utags.length; i++) {
        userTags.push({"title": utags[i]})
      }
      setUserTags(userTags)

  }, [docTags, curdocid])

  let handleKeyDown = (e, newValue) => {
      if (e.key === 'Enter' && value !== null) {
        setDocTagsLoading(true);
        docTags.push(value["title"]);
        setDocTagsLoading(false);
      }
  }

  const handleClickOpen = (note) => {
    setTemp(0);
    setCurNote(note)
    setcurdocid(note["docid"])
    setDocTags(note["tags"])
    setOpen(true);
  };

  const deleteTag = (dtag) => {
    let temp = curNote["tags"];
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] == dtag) {
        temp.splice(i, 1);
        break;
      }
    }
    curNote["tags"] = temp;
    setValue()
    docTags.push("")
    docTags.pop()
    setTemp(temp + 1)
    setDocTags(temp)
    setCurNote(curNote);
  };

  const handleCancel = () => {    
    for (let i = 0; i < temp; i++) {
      docTags.pop()
    }
    setTemp(0);
    setOpen(false);
  };

  const handleTimeClick = (url, userid, oid) => {
    // window.location.href = "http://readwme.app/n/" + getSingleNoteURL2(url, userid);

    window.open(getSingleNoteURL2(url, userid, oid));
  }

  const whenClickTag = (title) => {
    setFilterTag(title);
    const url = new URL(window.location);
    url.searchParams.set('tag', title);
    window.history.pushState({}, '', url);
  }

  const handleClose = async () => {
    let outlineRef = firestore.doc("users/" + user.emailaddy + "/outlines/" + curdocid);
    // update the tags collection for the user after closing the dialog
    await outlineRef.update({
      "tags": docTags
    });
    
    // add any new tags that are not currently in userTags to userTags
    for (let i = 0; i < docTags.length; i++) {
      if (!(userTagsList.includes(docTags[i]))) {
        userTagsList.push(docTags[i]);
      }
    }

    let userRef = firestore.doc("users/" + user.emailaddy);
    await userRef.update({
      "tags": userTagsList
    });

    setValue(null)
    setOpen(false);
  };

  // function tweetThis(text) {
  //   window.open(`https://twitter.com/intent/tweet?text=${text[0]}%20via%20@readwmeapp`, '_blank');
  // }

  function containsThisTag(tagObjects, tag) {
    if (tag == "all") {
      return true
    }
    return tagObjects.includes(tag);
  }

  if (user) {
    return (
      <>
      {
       Object.keys(notes).length >= 0 ?
      <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Card className={classes.userHeading}>
            <Tooltip 
              className={classes.profileName}
              title={"View all"}
              onClick={() => whenClickTag("all")}
            >
              {
                user.firstName !== "" ? 
                <h1 className={classes.userTitleStyle}> {`${user.firstName} ${user.lastName}`} </h1>
                :
                <h1 className={classes.userTitleStyle}> {"Welcome to readwme!"} </h1>
              }
            </Tooltip>
            
            {
              user.userbio !== "" ? 
              <p className={classes.userBioStyle}> {`${user.userbio}`} </p>
              :
              <p className={classes.userBioStyle}> {`Click the button at the top right to set up your profile.`} </p>
            }
            

            {userTags.map((tag, index) =>
              
              <>
              <Tooltip title={`${tag.title}`}>
                <a 
                  className={tag.title !== filterTag ? classes.hashtagsLinks : classes.hashtagsLinksFirst}
                  onClick={() => whenClickTag(tag.title)}
                > 
                  #{tag.title + " "}
                </a>
              </Tooltip>
              </>
            )}

          </Card>
          {
            Object.keys(notes).length === 0 ? 
            <Grid container direction="column" wrap="nowrap" spacing={2}>
  
              <Grid>
              
                <div className={classes.welcomeNote} item xs>
                <h4 className={classes.articleTitleog} >
                  <a className={classes.articleTitleog} href={`https://readwme.app?readwmeId=${userHlitId}`} target="_blank">{"Titles of pages you read will be here"} </a>
                  <br></br>
                  <a className={classes.domainname}> {"readwme.app"} </a>
                  <a className={classes.tstampstyleog}> {"Nov 28"} </a>
                </h4>

                <li className={classes.hlitlistog}>{"You have not highlighted anything yet."}</li>
                <li className={classes.hlitlistog}>{"Sentences you highlight in articles will show up here."}</li>
                <li className={classes.hlitlistog}>{"Start highlighting with the Chrome Extension!"}</li>
                        
              
                </div>

              </Grid>
              
                    
            </Grid>
            :
            <Grid container direction="column" wrap="nowrap" spacing={2}>
  
              { Object.keys(notes).map((item, ind) => (
                
                <Grid >
                {containsThisTag(notes[item].tags, filterTag) ? 
                
                <div className={classes.eachNote} key={ind.toString()} item xs>
                <h4>

                {/* <Tooltip 
                  className={classes.profileName}
                  title={"Open article in new tab"}
                > */}
                <a 
                  className={classes.articleTitle}
                  href={`https://${notes[item].paper_url}?readwmeId=${userHlitId}`}
                  target="_blank">
                    {notes[item].title}
                </a>

                <br></br>

                <a className={classes.domainname}> {getHostname(notes[item].paper_url)} </a>
                <a 
                  className={classes.tstampstyle}
                  onClick={() => handleTimeClick(notes[item].paper_url, userId, notes[item].oid)}
                >
                  {getTimeTheyRead(notes[item].tstamp.toDate().toString())}
                </a>
              </h4>
  
              {notes[item].hlits.map((snippet, ind2) =>
                <li key={ind2.toString()}>
                <a
                  className={classes.hlitlist} 
                  href={`https://${notes[item].paper_url}?readwmeId=${userHlitId}#:~:text=${encodeURIComponent(snippet)}`}
                  target="_blank" 
                >
                  {snippet}
                </a>
                </li>
              )}
  
              {/* <Tooltip title="Tweet this!">
                <TwitterIcon 
                  className={classes.sharebutton}
                  onClick={() => tweetThis(notes[item].hlits)}
                  fontSize="small"
                />
              </Tooltip> */}
  
              <Tooltip title="Copy link to clipboard">
                <ShareIcon 
                  className={classes.sharebutton}
                  onClick={() => {navigator.clipboard.writeText( getSingleNoteURL(notes[item].paper_url, userId, notes[item].oid))}}
                  fontSize="small"
                />
              </Tooltip>
              <Tooltip title="Export notes">
                <SaveAltIcon 
                  className={classes.sharebutton}
                  onClick={() => exportMarkdown(notes[item])}
                  fontSize="small" 
                />
              </Tooltip>              
              {
                  myPage ? 
                  <>
                  <Tooltip title="Edit tags">
                    <LocalOfferIcon
                      className={classes.sharebutton}
                      onClick={() => handleClickOpen(notes[item])}
                      fontSize="small" 
                    />
                  </Tooltip>
                    
                    </>
                    :
                    <></>
                }
                {Object.values(notes[item]["tags"]).map((tag, index) => (
                  <span
                    key={index}
                    className={classes.tagStyle}
                    // onClick={() => deleteTag(notes[item], tag)}
                  >
                    #{tag}
                  </span>
                ))}
                </div>
                
                : <> </> }
  
                
  
                </Grid>
              ))}
                    
            </Grid>
          }

        </Paper>
      </div>
      </> : 
      <></>
      }
      {
        tagsLoading ? <> </> :
      <Dialog className={classes.tagDialog} disableBackdropClick disableEscapeKeyDown open={open} onClose={() => handleClose}>
        <h3 className={classes.tagDialog}> Edit Tags </h3>
        {/* <h4 className={classes.tagDialog}> {notes[item].title} </h4> */}
        <DialogContent>

          {docTags.map((dtag, index) => (
            <Button
              variant="contained"
              key={index}
              className={classes.button}
              endIcon={<CloseIcon />}
              onClick={() => deleteTag(dtag)}
            >
              {dtag}
            </Button>
          ))}
          <br></br>
          <br></br>

          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              if (newValue != null) {
                if (newValue["inputValue"]) {
                  setValue({title: newValue["inputValue"]})
                  docTags.push(newValue["inputValue"])
                  setTemp(temp + 1)
                } else {
                  setValue({title: newValue})
                  if (!(docTags.includes(newValue["title"])) && newValue["title"] !== undefined ) {
                    docTags.push(newValue["title"])
                    setTemp(temp + 1)
                  }
                }
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
          //   id="free-solo-with-text-demo"
            options={userTags}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.title;
            }}
            // onKeyDown={(event, newValue) => {
            //   handleKeyDown(event, newValue)
            // }}
            onInput={(event, newValue) => {
              handleKeyDown(event, newValue)
            }}
            renderOption={(option) => option.title}
            style={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField {...params} label="Add a tag" variant="outlined" />
            )}
          />

          

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      }
      </>
    );
  } else return <><h1>no notes taken</h1></>
}
