import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Fab, Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";
import { firestore } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import UserCard from "../UserCard";
import OutlineCard from "../OutlineCard";
import AutoGridNoWrap from "../AutoGridNoWrap";
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import vid3 from '../../imgs/second.gif';

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    padding: theme.spacing(0, 3),
    backgroundColor: "#ffffff"
  },
  paper: {
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: 'none',
    boxShadow: 'none',
    backgroundColor: "#ffffff"
  },
  eachNote: {
    maxWidth: 600,
    margin: `${theme.spacing(1)}px auto`,
    padding: theme.spacing(2),
    outline: "none",
    borderRadius: "1px",
    boxShadow: 'none',
    backgroundColor: "#ffffff",
    // color: "red" -> this is font color
  },
  articleTitle: {
     paddingBottom: '0.1rem',
     textDecoration: 'none',
     color: "#1c1c1c"
  }
}));

function PrivacyPage() {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([])
  const [docsLoading, setDocsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [board, setBoard] = useState([]);
  const [boardURLs, setBoardURLs] = useState([]);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();

  useEffect(() => {
    return firestore
      .collection("digests")
      .doc("www.kff.org#medicare#issue-brief#limiting-private-insurance-reimbursement-to-medicare-rates-would-reduce-health-spending-by-about-350-billion-in-2021#")
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setBoard(snapshot.data());
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, [boardURLs]);

  useEffect(() => {
    firestore.collection("boards")
    .doc("healthcareresearch")
    .onSnapshot(
      (snapshot) => {
        setLoading(false);
        setBoardURLs(snapshot.data());
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );

  }, [userId]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user."
        description="Something went wrong when trying to retrieve the requested user."
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <>

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
      </Grid>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container direction="column" wrap="nowrap" spacing={2}>
            <Grid className={classes.eachNote} item xs>

                <h2 className={classes.articleTitle} >
                  <a className={classes.articleTitle}> Privacy Policy </a>
                </h2>

                <h3 className={classes.articleTitle} >
                  <a className={classes.articleTitle}> Collection and Use of Information </a>
                </h3>
                
                <p> 
                    We may collect personal information from you when you do any of the following on our website:
                </p>
                <ul>
                    Use a mobile device or web browser to access our content

                </ul>

                  <ul>
                    Contact us via email, social media, or on any similar technologies
                </ul>
                <p>
                    We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:
                <ul>
                to enable you to access and use our website, associated applications, and associated social media platforms
                </ul>
                    
                </p>

                <h3 className={classes.articleTitle} >
                  <a className={classes.articleTitle}> Personal Information </a>
                </h3>
                
                <h3 className={classes.articleTitle} >
                  <a className={classes.articleTitle}> Authentication and Web Browsing History </a>
                </h3> 
                We only use Google OAuth for logging you into the platform and do not share your email address or any other metadata with any third party. It is only for personal identification so you can access your notes and no other user can edit them. 
                
                <p>
                We will never publicly disclose authentication information. We never track your browsing history with the extension - we only will get the URLs of the specific articles that you choose to take notes on and choose to share within the application.
                </p>

            </Grid>

          </Grid>
        </Paper>
      </div>
      </>
    );
  }

  if (!loading) {
    return (
      <div className={classes.root}>
        
        <AutoGridNoWrap notes={docs}/>
      </div>
    );
  }

  return (
    <EmptyState
      image={<NoDataIllustration />}
      title="No profile."
      description="The user hasn‘t setup their profile."
    />
  );
}

export default PrivacyPage;
