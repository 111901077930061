import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Fab, Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";
import { firestore, auth } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import AutoGridNoWrap from "../AutoGridNoWrap";
import { Helmet } from 'react-helmet';

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 30,
    width: "95%",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#ffffff"
  }
}));

function UserOutlinesPage() {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([])
  const [docsLoading, setDocsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();
  const [myPage, setMyPage] = useState(false);

  // get the current user based on the browser url's params and set as cur_user
  useEffect(async () => {
    if (userId != undefined) {
      let username = userId;

      const usersRef = firestore.collection('users');
      const snapshot = await usersRef.where('username', '==', username).get();

      let cur_user;
      snapshot.forEach(doc => {
        cur_user = doc.data();
      });
      setLoading(false);
      setUser(cur_user);
    }
  }, [userId]);

  useEffect(() => {
    if (user != null) {
      if (auth.currentUser) {
        if (auth.currentUser.email == user.emailaddy) {
          setMyPage(true);
        }
      }
      let dox = {}
      firestore.collection("users/" + user.emailaddy + "/outlines")
      .orderBy('timestamp', 'desc')
      .get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          let hpname = doc.get("hpname");
          let title = doc.get("title");
          let snippets = doc.get("snippets");
          let tags = doc.get("tags");
          let tstamp = doc.get("timestamp");
          let oid = doc.get("outlineId");
          dox[hpname] = {"docid": doc.id, "title": title, "paper_url": hpname, "hlits": snippets, "tags": tags, "tstamp": tstamp, "oid": oid }
        })
        setDocs(dox)
        setDocsLoading(false)
      });
    }
  }, [user, auth.currentUser]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user."
        description="Something went wrong when trying to retrieve the requested user."
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (docsLoading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<readwmeLogo />}
        title="User doesn’t exist."
        description="The requested user doesn’t exist."
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  const hasProfile = user.firstName && user.lastName && user.username;

  if (!loading) {
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{user.firstName}'s Profile</title>
          <meta name="description" content="yeah"/>
          <meta property="og:title" content="userOutline" />
          <meta property="og:image" content="https://www.apple.com/v/iphone/home/t/images/home/og.png?201610171354" />

        </Helmet>
        <AutoGridNoWrap notes={docs} myPage={myPage} user={user}/>
      </div>
    );
  }

  return (
    <EmptyState
      image={<NoDataIllustration />}
      title="No profile."
      description="The user hasn‘t setup their profile."
    />
  );
}

export default UserOutlinesPage;
