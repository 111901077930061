import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonGroup,
  Button,
  IconButton,
  Divider,
  Menu,
  MenuItem,
  Link,
} from "@material-ui/core";
import UserAvatar from "../UserAvatar";
import { withRouter } from "react-router";

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: {
        anchorEl: null,
      },
    };
  }

  openMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      menu: {
        anchorEl,
      },
    });
  };

  closeMenu = () => {
    this.setState({
      menu: {
        anchorEl: null,
      },
    });
  };

  render() {
    // Properties
    const { location, performingAction, user, userData, roles } = this.props;

    // Events
    const {
      onAboutClick,
      onSettingsClick,
      onSignOutClick,
      onSignUpClick,
      onSignInClick,
    } = this.props;

    const { menu } = this.state;

    const menuItems = [
      {
        name: "Profile",
        to: user ? `/n/${user.username}` : null,
      },
      {
        name: "Edit Profile",
        onClick: onSettingsClick,
      },
      {
        name: "Sign out",
        divide: true,
        onClick: onSignOutClick,
      },
    ];

    return (
      <AppBar title="readwme home" style={{ backgroundColor: '#fff', boxShadow:'none', color:"#363636" }} position="static">
        <Toolbar>
          {/* <LibraryBooksIcon /> */}
          {/* <a href="/">
            <img src={logo} alt="readwme" height="45" width="45" />
          </a> */}
          <Box style={{ padding: "0.4em" }} display="flex" flexGrow={1}>
            <Typography color="inherit" variant="h6">
              <Link
                color="inherit"
                component={RouterLink}
                to="/"
                underline="none"
              >
                {"readwme"}
              </Link>
            </Typography>
            
          </Box>


          {user && (
            <>
              {roles.includes("admin") && (
                <Box mr={1}>
                  <Button
                    color="inherit"
                    component={RouterLink}
                    to="/admin"
                    variant="outlined"
                  >
                    Admin
                  </Button>
                </Box>
              )}

              <IconButton
                color="inherit"
                disabled={performingAction}
                onClick={this.openMenu}
              >
                <UserAvatar user={Object.assign(user, userData)} />
              </IconButton>

              <Menu
                anchorEl={menu.anchorEl}
                open={Boolean(menu.anchorEl)}
                onClose={this.closeMenu}
              >
                {menuItems.map((menuItem, index) => {
                  if (
                    menuItem.hasOwnProperty("condition") &&
                    !menuItem.condition
                  ) {
                    return null;
                  }

                  let component = null;

                  if (menuItem.to) {
                    component = (
                      <MenuItem
                        key={index}
                        component={RouterLink}
                        to={menuItem.to}
                        onClick={this.closeMenu}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  } else {
                    component = (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          this.closeMenu();

                          menuItem.onClick();
                        }}
                      >
                        {menuItem.name}
                      </MenuItem>
                    );
                  }

                  if (menuItem.divide) {
                    return (
                      <span key={index}>
                        <Divider />

                        {component}
                      </span>
                    );
                  }

                  return component;
                })}
              </Menu>
            </>
          )}

          {!user && (
            <ButtonGroup
              color="inherit"
              disabled={performingAction}
            >
              {/* <Button onClick={onSignUpClick}>Sign up</Button> */}
              <Button style={{ textTransform: "none", border: "none" }} onClick={onSignInClick}>Sign in</Button>

            </ButtonGroup>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

Bar.defaultProps = {
  performingAction: false,
};

Bar.propTypes = {
  // Properties
  performingAction: PropTypes.bool.isRequired,
  user: PropTypes.object,
  userData: PropTypes.object,

  // Events
  onAboutClick: PropTypes.func.isRequired,
  onSettingsClick: PropTypes.func.isRequired,
  onSignOutClick: PropTypes.func.isRequired,
};

export default withRouter(Bar);