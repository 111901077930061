import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Fab, Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";
import { firestore } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import UserCard from "../UserCard";
import OutlineCard from "../OutlineCard";
import AutoGridNoWrap from "../AutoGridNoWrap";
import request from "request";
import cheerio from "cheerio";
import Typography from '@material-ui/core/Typography';

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

import UserAvatar from "../UserAvatar";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 30,
    width: "95%",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#ffffff"
  }
}));

const exportMarkdownFile = () => {
  const element = document.createElement("a");
  const file = new Blob([document.getElementById('input').value],    
              {type: 'text/plain;charset=utf-8'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.txt";
  document.body.appendChild(element);
  element.click();
}

function TrendingPage() {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([])
  const [docsLoading, setDocsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [board, setBoard] = useState([]);
  const [boardURLs, setBoardURLs] = useState([]);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();

  useEffect(() => {
    return firestore
      .collection("digests")
      .doc("www.kff.org#medicare#issue-brief#limiting-private-insurance-reimbursement-to-medicare-rates-would-reduce-health-spending-by-about-350-billion-in-2021#")
      .onSnapshot(
        (snapshot) => {
          setLoading(false);
          setBoard(snapshot.data());
        },
        (error) => {
          setLoading(false);
          setError(error);
        }
      );
  }, [boardURLs]);

  useEffect(() => {
    firestore.collection("boards")
    .doc("healthcareresearch")
    .onSnapshot(
      (snapshot) => {
        setLoading(false);
        setBoardURLs(snapshot.data());
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );

  }, [userId]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user."
        description="Something went wrong when trying to retrieve the requested user."
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (loading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <>
      <Typography variant="h3" component="p">
        Health Tech Reads 
      </Typography>

      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <OutlineCard title={board['title']} hlits={board['highlights']} url={board['paper_url']} domain={board['domain']} />
        <OutlineCard/> 
      </Grid>
      </>
    );
  }

  const hasProfile = user.firstName && user.lastName && user.username;

  if (!loading) {
    return (
      <div className={classes.root}>
        
        <AutoGridNoWrap notes={docs}/>
      </div>
    );
  }

  return (
    <EmptyState
      image={<NoDataIllustration />}
      title="No profile."
      description="The user hasn‘t setup their profile."
    />
  );
}

export default TrendingPage;
