import React, { Component } from "react";

import PropTypes from "prop-types";

import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";

import HomePage from "../HomePage";
import AdminPage from "../AdminPage";
import UserPage from "../UserPage";
import UserOutlinesPage from "../UserOutlinesPage";
import UserSingleNotePage from "../UserSingleNotePage";
import TrendingPage from "../TrendingPage";
import PrivacyPage from "../PrivacyPage";
import NotFoundPage from "../NotFoundPage";

class Router extends Component {
  render() {
    // Properties
    const { user, roles, bar, userData } = this.props;
    // Functions
    const { openSnackbar } = this.props;
    return (
      <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
        {bar}

        <Switch>
          <Route path="/" exact>
            {
              <HomePage user={user} userData={userData} openSnackbar={openSnackbar} />
            }
          </Route>

          <Route path="/privacy" exact>
            <PrivacyPage /> 
          </Route>

          <Route path="/trending" exact>
            <TrendingPage /> 
          </Route>

          <Route path="/admin">
            {user && roles.includes("admin") ? (
              <AdminPage />
            ) : (
              <Redirect to="/" />
            )}
          </Route>

          <Route exact path="/p/:userId">
            {<UserPage />} 
          </Route>

          <Route exact path="/n/:userId">
            {<UserOutlinesPage />} 
          </Route>

          <Route path="/n/:userId/note/:noteId">
            {<UserSingleNotePage />} 
          </Route>

          <Route>
            <NotFoundPage />
          </Route>
        </Switch>
      </BrowserRouter>
    );
  }
}

Router.propTypes = {
  // Properties
  user: PropTypes.object,
  roles: PropTypes.array.isRequired,
  bar: PropTypes.element,
  userData: PropTypes.object, 

  // Functions
  openSnackbar: PropTypes.func.isRequired,
};

export default Router;
