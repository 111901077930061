import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

export default function OutlinedCard(props) {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  let title = props.title;
  let hlits = props.hlits;
  let url = props.url;
  let domain = props.domain;

  function handleClick(url) {
    window.open(url, '_blank');
  }

  if (title && hlits) {
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography onClick={() => handleClick(url)} variant="h6" component="h2">
          {title}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {domain}
        </Typography>

        {hlits.map((snippet, ind2) =>
          <p key={ind2.toString()}>{snippet}</p>
        )}
        {/* <Typography variant="body2" component="p">
          well meaning and kindly.
          <br />
          {'"a benevolent smile"'}
        </Typography> */}
      </CardContent>

    </Card>
    )
  }
  else {
    return <></>
  }
}
