import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Fab, Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";
import { firestore, auth } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import UserCard from "../UserCard/UserCard";
import OutlineCard from "../OutlineCard";
import SingleNoteGrid from "../SingleNoteGrid";
import { Helmet } from 'react-helmet';

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 30,
    width: "95%",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#ffffff"
  }
}));

const exportMarkdownFile = () => {
  const element = document.createElement("a");
  const file = new Blob([document.getElementById('input').value],    
              {type: 'text/plain;charset=utf-8'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.txt";
  document.body.appendChild(element);
  element.click();
}

function UserSingleNotePage() {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([])
  const [docsLoading, setDocsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(true);
  const [error, setError] = useState(null);
  const [myPage, setMyPage] = useState(false);
  const { userId, noteId } = useParams();
  const classes = useStyles();

  // called first when userId is loaded from params. go to firestore and look up user by params found in URL, then set user object
  useEffect(async () => {
    if (userId != undefined) {
      let username = userId;

      const usersRef = firestore.collection('users');
      const snapshot = await usersRef.where('username', '==', username).get();

      let cur_user;
      snapshot.forEach(doc => {
        cur_user = doc.data();
      });
      setLoading(false);
      setUser(cur_user);
    }

  }, [userId]);

  // once user object is set, look in the highlights collection for that user and then get all the documents
  useEffect(async () => {
    if (user != null) {
      if (auth.currentUser) {
        if (auth.currentUser.email == user.emailaddy) {
          setMyPage(true);
        }
      }
      let dox = {}

      let oid = parseInt(noteId)

      if (!isNaN(oid)) {
        console.log("hey why we here")
        console.log(isNaN(oid))
        firestore.doc("users/" + user.emailaddy).get().
        then((snapshot) => {
          let thedoc = snapshot.data()['outlineIds'][noteId];
          firestore.doc("users/" + user.emailaddy + "/outlines/" + thedoc).get().then((snapshot) => {
            let singleNote = snapshot.data();

            let hpname = singleNote["hpname"];
            let title = singleNote["title"];
            
            let snippets = singleNote["snippets"];
            let tags = singleNote["tags"];
            let tstamp = singleNote["timestamp"];
            dox[hpname] = {"docid": "doc.id", "title": title, "paper_url": hpname, "hlits": snippets, "tags": tags, "tstamp": tstamp }
  
            setDocs(dox)
            setDocsLoading(false)  
          })
        })
      } else {
        console.log('log 2')
        firestore.doc("users/" + user.emailaddy + "/outlines/" + noteId).get().then((snapshot) => {
          let singleNote = snapshot.data();

          let hpname = singleNote["hpname"];
          let title = singleNote["title"];
          
          let snippets = singleNote["snippets"];
          let tags = singleNote["tags"];
          let tstamp = singleNote["timestamp"];
          dox[hpname] = {"docid": "doc.id", "title": title, "paper_url": hpname, "hlits": snippets, "tags": tags, "tstamp": tstamp }

          setDocs(dox)
          setDocsLoading(false)
        })
      }

      // const snapshot = await highlightRef.where('url', '==', decodeURIComponent(noteId)).get()

      // snapshot.forEach(doc => {
      //   let cur_url = doc.get("url")
      //   let cur_title = doc.get("title")

      //   if (!(cur_url in dox)) {
      //     // dox[cur_url] = {"title": cur_title, "paper_url": cur_url, "hlits": [doc.get("string")]}
      //     dox = Object.assign({cur_url: {"title": cur_title, "paper_url": cur_url, "hlits": [doc.get("string")]}}, dox);
      //   } else {
      //     dox[cur_url]['hlits'].push(doc.get("string"))
      //   }
      // });

      // setDocs(dox);
      // setDocsLoading(false);
    }
  }, [user, auth.currentUser]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user."
        description="Something went wrong when trying to retrieve the requested user."
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (docsLoading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist."
        description="The requested user doesn’t exist."
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  const hasProfile = user.firstName && user.lastName && user.username;

  if (!loading) {
    // document.title = "This is demo Component";
    return (
      <div className={classes.root}>
        <Helmet>
          <title>{user.firstName}'s Highlights </title>
          <meta name="description" content="yeah"/>
          <meta property="og:title" content="userOutline" />
          {/* <meta property="og:image" content="https://www.apple.com/v/iphone/home/t/images/home/og.png?201610171354" /> */}

        </Helmet>
        <SingleNoteGrid notes={docs} myPage={myPage} user={user}/>
      </div>
    );
  }

  return (
    <EmptyState
      image={<NoDataIllustration />}
      title="No profile."
      description="The user hasn‘t setup their profile."
    />
  );
}

export default UserSingleNotePage;
