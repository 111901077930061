import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { Grid, Fab, Box } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { makeStyles } from "@material-ui/core/styles";
import { Refresh as RefreshIcon, Home as HomeIcon } from "@material-ui/icons";
import { firestore, auth } from "../../firebase";
import EmptyState from "../EmptyState";
import Loader from "../Loader";
import UserCard from "../UserCard";
import OutlineCard from "../OutlineCard";
import AutoGridNoWrap from "../AutoGridNoWrap";

import { ReactComponent as ErrorIllustration } from "../../illustrations/error.svg";
import { ReactComponent as NoDataIllustration } from "../../illustrations/no-data.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    margin: 30,
    width: "95%",
  },
  root: {
    flexGrow: 1,
    backgroundColor: "#ffffff"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: "#ffffff"
  }
}));

const exportMarkdownFile = () => {
  const element = document.createElement("a");
  const file = new Blob([document.getElementById('input').value],    
              {type: 'text/plain;charset=utf-8'});
  element.href = URL.createObjectURL(file);
  element.download = "myFile.txt";
  document.body.appendChild(element);
  element.click();
}

function UserPage() {
  const [loading, setLoading] = useState(true);
  const [docs, setDocs] = useState([])
  const [docsLoading, setDocsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [notes, setNotes] = useState([]);
  const [notesLoading, setNotesLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userId } = useParams();
  const classes = useStyles();
  const [myPage, setMyPage] = useState(false);

  useEffect(async () => {
    if (userId != undefined) {
      let username = userId;

      const usersRef = firestore.collection('users');
      const snapshot = await usersRef.where('username', '==', username).get();
      

      let cur_user;
      snapshot.forEach(doc => {
        cur_user = doc.data();
      });
      setLoading(false);
      setUser(cur_user);
    }

  }, [userId]);

  useEffect(() => {
    if (user != null) {
      if (auth.currentUser.email == user.emailaddy) {
        setMyPage(true);
      }
      let dox = {}
      firestore.collection("users/" + user.emailaddy + "/outlines")
      .get()
      .then((snapshot) => {
        snapshot.forEach(doc => {
          let cur_url = doc.get("hpname")
          let cur_title = doc.get("title")


          dox[cur_url] = {"title": cur_title, "paper_url": cur_url, "hlits": [doc.get("snippets")]}
          // if (!(cur_url in dox)) {
          //   dox[cur_url] = {"title": cur_title, "paper_url": cur_url, "hlits": [doc.get("snippets")]}
          //   // dox = Object.assign({cur_url: {"title": cur_title, "paper_url": cur_url, "hlits": [doc.get("string")]}}, dox);
          // } else {
          //   dox[cur_url]['hlits'].push(doc.get("string"))
          // }
        })
        setDocs(dox)
        setDocsLoading(false)
      });
    }
  }, [user]);

  if (error) {
    return (
      <EmptyState
        image={<ErrorIllustration />}
        title="Couldn’t retrieve user."
        description="Something went wrong when trying to retrieve the requested user."
        button={
          <Fab
            variant="extended"
            color="primary"
            onClick={() => window.location.reload()}
          >
            <Box clone mr={1}>
              <RefreshIcon />
            </Box>
            Retry
          </Fab>
        }
      />
    );
  }

  if (docsLoading) {
    return <Loader />;
  }

  if (!user) {
    return (
      <EmptyState
        image={<NoDataIllustration />}
        title="User doesn’t exist."
        description="The requested user doesn’t exist."
        button={
          <Fab variant="extended" color="primary" component={Link} to="/">
            <Box clone mr={1}>
              <HomeIcon />
            </Box>
            Home
          </Fab>
        }
      />
    );
  }

  const hasProfile = user.firstName && user.lastName && user.username;

  if (!loading) {
    return (
      <div className={classes.root}>
        {/* <UserAvatar user={Object.assign(user, guser)} /> */}
        <UserCard user={user} />
        {/* <OutlineCard/> */}
        <AutoGridNoWrap notes={docs} myPage={myPage}/>
      </div>
    );
  }

  return (
    <EmptyState
      image={<NoDataIllustration />}
      title="No profile."
      description="The user hasn‘t setup their profile."
    />
  );
}

export default UserPage;
